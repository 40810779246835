import { Carousel } from './init-carousel';
import { Navigation, Grid } from 'swiper/modules';

const carouselOptions = {
    modules: [Navigation, Grid],
    navigation: {
        nextEl: '.logo-carousel__next',
        prevEl: '.logo-carousel__prev',
    },
    grid: {
        rows: 2,
        fill: 'row',
    },
    slidesPerView: 3,
    spaceBetween: 15,
    breakpoints: {
        680: {
            slidesPerView: 5,
            spaceBetween: 40,
        },
    },
};

const carousel = document.querySelector('[data-carousel="logos"]');

const logoCarousel = new Carousel(carousel, carouselOptions);

logoCarousel.initSwiper();
